<template>
  <div class="go-in">
    <banner img="../assets/img/bgtop.jpg" title="" />
    <div class="section" v-loading="loading">
      <div class="section-content">
        <div class="content-summary">
          <div class="summary-left">
            <p class="title">公司简介</p>
            <p class="eTitle">ABOUT US</p>
            <p class="content">
              江苏中慧传承工程建设有限公司始建于1956年，原为南通粮食局下属工程处，1996年经股份合作制改制为南通市天泰建筑安装工程总公司，2012年更名为南通天泰建设工程有限公司，2021年工商变更为江苏中慧传承工程建设有限公司。公司现有各类从业人员1828人，其中具有中高级职称人员167人，一般工程技术人员308人，拥有各类大中型建筑机械设备和仪器400多台套，能够承担各类高、大、难、新工程的施工总承包。
              <br>长期以来，公司坚持以求真务实的态度、匠心精造的精神、智造发展的理念、诚信守诺的服务饮誉于市场，先后创建省、市级优质工程和星级标化工地158个，目前，我公司的施工队伍主要分布在江、沪、鲁等省的南通、上海、青岛等广大地区。
            </p>
          </div>
          <div class="summary-right">
            <img src="../assets/img/jianjietopmin.jpg" alt />
          </div>
        </div>
        <el-divider class="el-divider-active">
          <i class="el-icon-arrow-down el-icon-arrow-down-active"></i>
        </el-divider>
        <!-- 发展历程 -->
        <div class="content-course">
          <div class="top">
            <h3>发展历程</h3>
            <p>DEVELOPMENT</p>
            <div class="border"></div>
            <div class="timeline"></div>
          </div>
          <div class="course-time">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(item,index) in courseList" :key="index">
                <div class="time-show">
                  <div class="time-show-item" v-for="(courseOne,one) in item" :key="one">
                    <div class="item-top" :class="{'order-top' : one%2===1}"></div>
                    <el-divider>
                      <i class="el-icon-mobile-phone"></i>
                    </el-divider>
                    <div class="item-bottom" :class="{'order' : one%2===1}">
                      <div class="item-bottom-content">
                        <p>{{courseOne.content}}</p>
                        <p>{{courseOne.year}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>

        <div class="content-culture">
          <el-row style="width: 98%">
            <el-col :span="8">
              <div>
                <h3>企业理念</h3>
                <p>精工细作 用心服务</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <h3>核心价值观</h3>
                <p>诚信 团结 责任 高效</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <h3>企业愿景</h3>
                <p>汇聚科技资源，创新引领发展，成为创新与科技为核心竞争力的新时代建设集团</p>
              </div>
            </el-col>
          </el-row>

<!--          <span>确保工程无重大安全事故</span>-->
<!--          <span>确保工程施工单位无大面积返工</span>-->
<!--          <span>确保工程材料及设备无伪劣产品</span>-->
<!--          <span>确保工程管理留下痕迹、实施过程可追溯</span>-->
        </div>
        <!-- 公司荣誉 -->
<!--        <div class="content-honor">
          <div class="honor-big-img">
            <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">
              <img v-lazy="dialogUrl" alt />
            </el-dialog>
          </div>
          <div class="top">
            <h3>公司荣誉</h3>
            <p>HONOR</p>
            <div class="border"></div>
          </div>
          <ul class="honor-show">
            <li v-for="(honor,index) in honorList" :key="index">
              <img
                v-lazy="imgserver+honor.img"
                @click="dialogTableVisible = true ;dialogUrl = imgserver + honor.img;dialogTitle= honor.remark"
              />
            </li>
          </ul>
          <p>点击图片查看大图</p>
        </div>-->
        <!-- 团队风采 -->
<!--        <div class="content-team">
          <div class="top">
            <h3>团队风采</h3>
            <p>TEAM</p>
          </div>
          <el-carousel :interval="4000" type="card">
            <el-carousel-item v-for="(team,index) in teamItem" :key="index">
              <div class="swiper-img" v-lazy:background-image="imgserver + team.img"></div>
            </el-carousel-item>
          </el-carousel>
        </div>-->
        <!-- 合作伙伴 -->
<!--        <div class="content-partner">
          <div class="top">
            <h3>合作伙伴</h3>
            <p>RARTNERS</p>
            <ul class="partner-img">
              <li v-for="(partner,i) in partnerImg" :key="i">
                <img v-lazy="imgserver+partner.img" alt />
              </li>
            </ul>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { baseService, getGoInData } from "@/services";
import Banner from "../components/Banner";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    Banner,
    swiper,
    swiperSlide
  },
  data() {
    return {
      loading: true,
      honorList: [],
      partnerImg: [],
      courseList: [],
      teamItem: [],
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      dialogTableVisible: false,
      dialogUrl: "",
      dialogTitle: ""
    };
  },
  mounted() {
    getGoInData().then(
      baseService.spread(
        (responseHonor, responseEnterprise, responseTeam, responseCourse) => {
          //this.honorList = responseHonor;
          //this.partnerImg = responseEnterprise;
          //this.teamItem = responseTeam;

          var groupCount = Math.ceil(responseCourse.length / 2);
          for (let i = 0; i < groupCount; i++) {
            let img2 = [];
            for (let j = 0; j < 2; j++) {
              if (responseCourse.length - 1 >= i * 2 + j) {
                img2.push(responseCourse[i * 2 + j]);
              }
            }
            this.courseList.push(img2);
          }
          this.loading = false;
        }
      )
    );
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.go-in {
  width: 100%;
  height: 100%;
  background-color: #14679f;
  position: relative;
  overflow: hidden;
  .section {
    width: 100%;
    &-content {
      width: 1240px;
      margin: 0 auto;
      background-color: #fff;

      .content-summary {
        //height: 500px;
        display: flex;
        justify-content: space-around;
        padding: 100px 0;
        .summary-left {
          width: 600px;
          .title {
            font-size: 25px;
            color: #e13834;
          }
          .eTitle {
            font-size: 17px;
            color: #e13834;
            padding: 20px 0;
          }
          .content {
            color: #14679f;
            font-size: 14px;
            text-indent: 25px;
            line-height: 30px;
          }
        }

        .summary-right {
          width: 400px;
          height: 310px;
          border: 2px solid #1d42b9;
          //animation: imgboxkey 4s infinite;
          border-radius: 10px;
          margin-top: 80px;
          text-align: center;

          img {
            width: 360px;
            height: 270px;
            margin-top: 20px;
            //animation: imgbo 4s infinite;
          }
        }
      }

      //发展历程
      .content-course {
        padding: 50px 0;
        .course-time {
          width: 1000px;
          height: 400px;
          margin: 20px auto;
          .swiper-container {
            height: 100%;
          }
          .time-show {
            width: 700px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            .time-show-item {
              width: 350px;
              height: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: column;

              .item-top,
              .item-bottom {
                height: 190px;
              }
              .item-bottom {
                // display: flex;
                // align-content: center;
                .item-bottom-content {
                  background-color: #1667a0;
                  margin: 20px 0;
                  p {
                    color: #fff;
                    text-align: center;
                    padding: 15px;
                  }
                }
              }
            }
          }
        }
      }

      //企业文化
      .content-culture {
        //height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 100px;
        background-color: #c2daeb;
        h3 {
          color: #3c6088;
          font-size: 26px;
        }
        p {
          color: #3c6088;
          font-size: 26px;
          padding: 5px 0;
        }
        span {
          font-weight: 400;
          line-height: 36px;
          font-size: 18px;
          padding: 5px 0;
        }
      }

      //公司荣誉
      .content-honor {
        padding: 50px 0;
        .honor-show {
          width: 1000px;
          margin: 30px auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: flex-start;

          li {
            width: 220px;
            //height: 320px;
            margin-left: 15px;
            list-style: none;
            border: 1px solid palegoldenrod;

            img {
              width: 220px;
              height: 100%;
            }
          }
        }
        p {
          text-align: center;
          color: #3c6088;
        }
      }

      //团队风采
      .content-team {
        padding: 50px 100px;
        .swiper-img {
          height: 400px;
          background: no-repeat center;
          background-size: cover;
        }
      }

      //合作伙伴
      .content-partner {
        padding: 50px 0;
        .partner-img {
          width: 950px;
          margin: 20px auto;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            width: 107px;
            height: 107px;
            list-style: none;
            margin: 10px 25px;

            img {
              width: 100%;
              height: 100%;
              border: 1px solid rgb(194, 218, 235);
            }
          }
        }
      }
    }
  }
}

@keyframes imgboxkey {
  0% {
    border: solid rgb(29, 66, 185) 2px;
  }
  40% {
    border: solid rgb(255, 255, 255) 2px;
  }
  60% {
    border: solid rgb(255, 255, 255) 2px;
  }
  100% {
    border: solid rgb(29, 66, 185) 2px;
  }
}

@keyframes imgbo {
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px #ababab;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 5px #ababab;
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px #ababab;
  }
}
.el-divider--horizontal {
  margin: 1px 0;
}

.top {
  h3,
  p {
    text-align: center;
    color: #3c6088;
    font-weight: 400;
    padding: 10px 0;
  }
  h3 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  .border {
    border-bottom: 1px solid #3c6088;
    width: 15%;
    margin: 0 auto;
  }
}
// .swiper-button-disabled {
//   display: none;
// }
.order {
  order: -1;
}
.order-top {
  order: 1;
}
.el-divider {
  background-color: red;
  height: 3px;
  .el-divider__text {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0px;
    color: #fff;
    border: 3px solid red;
  }
}
.el-divider-active {
  background-color: #3c6088;
}
.honor-big-img {
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;
  padding-bottom: 20px;
  padding: 5%;
  //background-color: #14679f;
  .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;

    .el-dialog__body {
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}
.mobile {
  .section-content {
    width: auto;
    .content-summary {
      display: block;
      padding: 20px;
      .summary-left {
        width: auto;
        margin: 0 10px;
      }
      .summary-right {
        width: 100%;
        height: auto;
        border: none;
        margin-top: 10px;
        text-align: center;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .content-course {
      padding: 20px;
      width: auto;
      height: auto;
      .course-time {
        width: auto;
        .time-show {
          width: 70%;
          .time-show-item {
            width: 50%;
            font-size: 14px;
            padding-right: 5px;
            .el-divider {
              display: none;
            }
            .item-top {
              height: 0;
            }
          }
        }
      }
    }
    .content-culture {
      padding: 10px;
    }
    .content-honor {
      .honor-show {
        width: auto;
        li {
          width: auto;
          height: auto;
          margin-left: 0;
          list-style: none;
          border: none;
          text-align: center;
          img {
            width: 90%;
          }
        }
      }
      .honor-big-img {
        .el-dialog {
          width: 100%;
        }
      }
    }
    .content-team {
      width: 100%;
      padding: 0;
    }
  }
}
</style>